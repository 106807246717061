.h-footer{
    height:30vh;
    width:100%;
}

.icons{
    
    column-gap: 25px;
}

.icon{
    width:62px;
    height: 62px;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    font-size: 60px;
    color: #fff;
    border-radius: 50%;
    outline: 0px solid #fff;
    transition: outline-offset, outline-color, background-color;
    transition-duration: .25s;
}

.icon:hover{
outline-offset:4px;
}

.icon:hover i{
    animation: shake .25s;
}

.icon--instagram:hover{
    background-image: 
    radial-gradient(circle at 30% 107%,
    #fdf497 0%, #fdf497 5%,
    #fd5949 45%, #d6249f 60%,
    #285AEB 90%);
    
    outline-color:#a02d76;
}

.icon--twitter:hover{
    background-color: #1da1f2;
    outline-color: #1da1f2;
}

.icon--linkedin:hover{
    background-color: #0077b5;
    outline-color: #0077b5;
}

.icon--github:hover{
    background-color: #2ea44f;
    outline-color: #2ea44f;
}


@keyframes shake {
    10%{transform: rotate(15deg);}
    20%{transform: rotate(-15deg);}
    30%{transform: rotate(15deg);}
    40%{transform: rotate(-15deg);}
}



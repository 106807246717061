@tailwind base;
@tailwind components;
@tailwind utilities;

.swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%20transform%3D'rotate(180)'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'gray'%20stroke%3D'gray'%20stroke-width%3D'4'%2F%3E%3C%2Fsvg%3E") !important;
  background-repeat: no-repeat;
  background-color: white;
  padding: 1.35rem;
  border-radius: 50%;
  background-size: 30% auto;
  background-position: center;
}

.swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'gray'%20stroke%3D'gray'%20stroke-width%3D'4'%2F%3E%3C%2Fsvg%3E") !important;
  background-repeat: no-repeat;
  background-color: white;
  padding: 1.35rem;
  border-radius: 50%;
  background-size: 30% auto;
  background-position: center;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev::after {
  display: none
}

@import 'react-image-crop/dist/ReactCrop.css';

body{
 overflow-x: hidden;
 max-width:100vw;

}
*{
  scroll-behavior: smooth;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.boxshadow1{
  -webkit-box-shadow: 2px 2px 4px #aaa;
}
.bdr{
  border: 1px solid black;
}
.bs1{
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

@media screen and (max-width: 750px){
  .carousal{
    display: none;
   }
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}


.bdr{
  border:1px solid black;
}

.tc{
  max-width: 90%;
  margin :0 auto;
  text-align: justify;
}

.tc h2{
  /* margin-top: 20px; */
  font-weight: 700;
  font-size: 24px;
}

.tc ul{
  list-style-type: disc;
  margin-left: 20px;
}

.tc p{
  text-align: justify;
}

.ttt{
  display: none;
}

.showOnHover:hover .ttt{
  display: block;
} 
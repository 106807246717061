.container{
    width: 100%;
    z-index: 1;
  }
  
  .progressbar {
    counter-reset: step;
  }
  .progressbar li { 
    list-style: none;
    display: inline-block;
    right: 6vw;
    width: 14.33%;
    position: relative;
    text-align: center;
    cursor: pointer;
  }
  
  @media only screen and (max-width: 640px) {
    .progressbar li  { 
    right: 0vw;
  }
}

  .progressbar li:before{
    content:counter(step);
    counter-increment: step;
    width: 60px;
    height: 60px;
    border: 2px solid #bebebe;
    display: block;
    margin: 0 auto 10px auto;
    border-radius: 100%;
    font-size: 30px;
    line-height: 57px;
    background: #dadbd3;
    color: #ffffff;
    text-align: center;
    font-weight: bold;
  }

  .progressbar li:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #dadbd3;
    top: 25px;
    left: -50%;
    z-index : -1;
  }
  .progressbar li:first-child:after {
    content: none;
  }
  .progressbar li.active {
   color: black;
  }

  .progressbar li.active:before {
    border-color: #1e68ad;
    background-color: #1e68ad;
  }
   
  .progressbar li.active + li:after {
    background-color: #1e68ad;
  }

  .progressbar li.mainactive + li::after{
    background-color: forestgreen;
    border-color: forestgreen;
  }
  .progressbar li.mainactive:before {
    background-color: forestgreen;
    border-color: forestgreen;
  }
  .maingreen{

  }
.video-div-header {
  font-family: "cfmedium";
  font-size: 3.2rem;
}

.video-div-description {
  font-family: "cfmedium";
  font-size: 2.5rem;
}
.video-btn {
  font-family: "cfmedium";
  font-size: 1.5rem;
  border-radius: 15px;
  background: #000000;
  color: #ffffff;
  width: fit-content;
}
.react-player {
  width: 60% !important;
  height: 500px !important;
}

@media (min-width: 1600px) {
  .video-div-header {
    font-size: 3.5rem;
  }
  .video-div-description {
    font-size: 2rem;
  }
  .video-btn {
    font-size: 2rem;
  }
  .react-player {
    width: 70% !important;
    height: 650px !important;
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .react-player {
    width: 75% !important;
    height: 484.5px !important;
  }
  .video-div-header {
    font-size: 3rem;
  }
  .video-div-description {
    font-size: 1.5rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .react-player {
    width: 80% !important;
    height: 400px !important;
  }
  .video-div-header {
    font-size: 3rem;
  }
  .video-div-description {
    font-size: 1.2rem;
  }
  .video-btn {
    font-size: 1.5rem;
  }
}
@media (max-width: 425px) {
  .video-div-header {
    font-size: 1.8rem;
  }
  .video-div-description {
    font-size: 1.2rem;
  }
  .video-btn {
    font-size: 0.8rem;
  }
  .react-player {
    width: 100% !important;
    height: 237px !important;
  }
}
@media (min-width: 426px) and (max-width: 768px){
  .video-div-header {
    font-size: 1.8rem;
  }
  .video-div-description {
    font-size: 1.2rem;
  }
  .video-btn {
    font-size: 0.8rem;
  }
  .react-player {
    width: 69% !important;
    height: 296px !important;
  }
}

input[type='text'] {
  box-sizing: border-box;
  outline: none;
  border: none;
}

/* input[type=radio],
input[type=checkbox]{
  /* Hide original inputs */
/* visibility: hidden;
  position: absolute; */
/* } */

input[type='radio'] {
  -ms-transform: scale(2); /* IE 9 */
  -webkit-transform: scale(2); /* Chrome, Safari, Opera */
  transform: scale(2);
  border: 1px solid #dcddd7 !important;
}

.w-new{
    width: 80%;
}

.w-new1{
    width: 50%;
}

.w-new2{
  width:50%;
}

.mt-new {
    margin-top: 35rem/* 16px */;
}

.h-screen1 {
  height: 80vh;
}

.w-full1{
  width: 110vw;
  position: relative;
  left:-10vw;
}

.carousel {
    display: inline-flex;
    overflow-x: hidden;
  /*  scroll snap is a great feature which will center the image on snap on touch screen devices  */
    scroll-snap-type: x mandatory;
  /* all below will hide the scrollbar on all browsers.    */
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
  }
 
.hide-scroll-bar::-webkit-scrollbar { 
  height: 6px;
}
.hide-scroll-bar::-webkit-scrollbar-thumb {
  background-color:#71717a; 
  /* background-color: #575761;  */
}
.hide-scroll-bar::-webkit-scrollbar-thumb:hover { 
  background-color: #52525b; 
}
.hide-scroll-bar::-webkit-scrollbar-track {
  background-color:#e4e4e7; 
} 

